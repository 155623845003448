import React from "react"
import Block from "../components/block"

export default () => (
  <Block>
    <p> Hi, I am Aaron DeRuvo</p>
    <p>
      I work with product owners and designers to create interfaces for web and
      mobile apps that feel amazing to use. Fluent in JavaScript (and
      Typescript), I've <a href="https://github.com/aaronmgdr">coded</a> React
      based front-ends for startups like <a href="https://celo.org">Celo</a> and{" "}
      <a href="https://youearnedit.com">YouEarnedit</a>. I practice and believe
      in progress over perfection, listening with great attention, and diving
      into discomfort.
    </p>
    <p>
      Occasionally I will{" "}
      <a href="https://medium.com/@aaronmgdr">
        write about software, work, relationship and other topics.
      </a>
    </p>
    <p>Currently, I live in San Francisco, California.</p>
  </Block>
)
